/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-02 17:53:37
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-09-27 11:19:51
 * @FilePath: /pim-frontend/src/main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive, createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import * as directives from "@/directives";

//imports for app initialization
//import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
//import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { InlineSvgIcon } from "magicbean-saas-common";

import "@/core/plugins/keenthemes";
import "bootstrap";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Emitter = require("tiny-emitter");
const emitter = new Emitter();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
//MockAdapter.init(app);
//initApexCharts(app);
initInlineSvg(app);
app.component("inline-svg-icon", InlineSvgIcon);
initVeeValidate();

app.use(i18n);

app.mount("#app");
