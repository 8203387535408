/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2022-05-31 22:12:47
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-13 11:32:39
 * @FilePath: \magicbean-saas-pim\src\core\language\en\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import menu from "magicbean-saas-common/src/core/language/en/menu.json";
import common from "./common.json";
import products from "./products.json";
import dsSkuManagement from "./ds-sku-management.json";
import hsCodeManagement from "./hs-code-management.json";

export default {
  menu,
  common,
  products,
  dsSkuManagement,
  hsCodeManagement,
};
