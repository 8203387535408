
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from "vue";

import FsLightbox from "fslightbox-vue/v3";
import { printStart2 } from "@/core/directive/function/print";
import { PrinterConfigTaskDocumentContent } from "./core/directive/interface/print";
import store from "./store";
import { Mutations } from "./store/enums/StoreEnums";
import { isMobile } from "./core/directive/function/common";
import _ from "lodash";
import zhCN from "element-plus/es/locale/lang/zh-cn";
import enUS from "element-plus/es/locale/lang/en";
import deDE from "element-plus/es/locale/lang/de";

export default defineComponent({
  name: "app",
  components: {
    FsLightbox,
  },
  setup() {
    const currentInstance: any = getCurrentInstance();

    const options = ref({
      toggler: false,
      sources: [] as any[],
      sourcesSlide: 1,
    });

    const locale = computed(() => {
      const lang = store.getters.currentUser?.language;
      switch (lang) {
        case "zh_CN":
          return zhCN;
        case "en_US":
          return enUS;
        case "de_DE":
          return deDE;
        default:
          return enUS;
      }
    });

    const previewImages = () => {
      currentInstance.proxy.emitter.on("preview-images", function (obj) {
        options.value.sources = obj.sources;
        options.value.toggler = true;
        setTimeout(() => {
          options.value.sourcesSlide = obj.sourcesSlide;
        }, 0);
      });
    };

    const imageOpen = () => {
      // options.value.toggler = true;
    };

    const imageClose = () => {
      options.value = {
        toggler: false,
        sources: [] as any[],
        sourcesSlide: 1,
      };
    };

    const allPrint = () => {
      currentInstance.proxy.emitter.on(
        "all-print",
        function (contents: PrinterConfigTaskDocumentContent[]) {
          printStart2(contents);
        }
      );
    };

    const offEmitter = () => {
      currentInstance.proxy.emitter.off();
    };

    const checkViewport = (e) => {
      if (e.matches) {
        //手机版
        store.commit(Mutations.SET_WINDOW_IS_MOBILE, true);
      } else {
        //桌面版
        store.commit(Mutations.SET_WINDOW_IS_MOBILE, false);
      }
    };

    onMounted(() => {
      previewImages();
      // printConnect();
      allPrint();
      checkViewport(isMobile);
      isMobile.addListener(checkViewport);
      // iOS10以后版本不接受meta标签
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    });

    onUnmounted(() => {
      // printClose();
      offEmitter();
    });

    return {
      options,
      locale,
      previewImages,
      imageOpen,
      imageClose,
    };
  },
});
