import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import {
  MBLayout,
  MBAuth,
  MBSignIn,
  MBError404,
  MBError500,
} from "magicbean-saas-common";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // {
      //   path: "/products/",
      //   name: "products",
      //   component: () => import("@/views/products/products-management/Index.vue"),
      // },
      // {
      //   path: "/products/:id/Information",
      //   name: "products-information",
      //   component: () => import("@/views/products/products-management/Information.vue"),
      // },
      {
        path: "/products/ds-sku-management",
        name: "products-ds-sku-management",
        component: () => import("@/views/products/ds-sku-management/Index.vue"),
      },
      {
        path: "/products/ds-sku-management/:id/Information",
        name: "products-ds-sku-management-information",
        component: () =>
          import("@/views/products/ds-sku-management/Information.vue"),
      },
      {
        path: "/products/hs-code-management",
        name: "products-hs-code-management",
        component: () =>
          import("@/views/products/hs-code-management/Index.vue"),
      },
      {
        path: "/catalog/categories/",
        name: "categories",
        component: () => import("@/views/catalog/categories/Index.vue"),
      },
      {
        path: "/catalog/categories/:id/Information",
        name: "categories-detail",
        component: () => import("@/views/catalog/categories/Information.vue"),
      },
      {
        path: "/catalog/brands/",
        name: "brands",
        component: () => import("@/views/catalog/brands/Index.vue"),
      },
      {
        path: "/catalog/brands/:id/information",
        name: "brands-information",
        component: () => import("@/views/catalog/brands/Information.vue"),
      },
    ],
  },
  {
    path: "/",
    component: MBAuth,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: MBSignIn,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH);
    store.dispatch(Actions.GET_MENU_ITEMS, to.path);
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
