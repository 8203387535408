import menu from "magicbean-saas-common/src/core/language/de/menu.json";
import common from "./common.json";
import products from "./products.json";
import dsSkuManagement from "./ds-sku-management.json";
import hsCodeManagement from "./hs-code-management.json";

export default {
  menu,
  common,
  products,
  dsSkuManagement,
  hsCodeManagement,
};
