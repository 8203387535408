import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getBrandsList(params) {
    return ApiService.post(Config.pimApiUrl + "/api/brand/index", params);
  },
  addBrands(params) {
    return ApiService.post(Config.pimApiUrl + "/api/brand/store", params);
  },
  updateBrands(params) {
    return ApiService.post(Config.pimApiUrl + "/api/brand/update", params);
  },
  deleteBrands(params) {
    return ApiService.post(Config.pimApiUrl + "/api/brand/delete", params);
  },
  getBrandsInfo(params) {
    return ApiService.post(Config.pimApiUrl + "/api/brand/show", params);
  },
};
