/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2024-06-13 14:16:05
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-25 19:54:20
 * @FilePath: \magicbean-saas-pim\src\core\api\hscode.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getHsCodeList(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/list",
      params
    );
  },
  getHsCodeDetails(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/show",
      params
    );
  },
  saveHsCode(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/save",
      params
    );
  },
  updateHsCode(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/update",
      params
    );
  },
  deleteHsCode(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/delete",
      params
    );
  },
  uploadHsCodeExcel() {
    return Config.pimApiUrl + "/api/hscode/feedback/file";
  },
  exportHsCode(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/export",
      params,
      { responseType: "blob" }
    );
  },
  exportHsCodeLog(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/export-log",
      params,
      { responseType: "blob" }
    );
  },
  getHsCodeLog(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/log",
      params
    );
  },
  checkHsCode(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/hscode/feedback/check",
      params
    );
  },
};
