import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { nextTick } from "vue";
import { CommonDateType } from "../type/common";
import moment from "moment";
import momentTimezone from "moment-timezone";
import fileDownload from "js-file-download";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonLanguageType } from "../type/common";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { CommonModuleRouter } from "../interface/common";
import { getProductsDetailModule, getProductsModule } from "./products";

export const modalShowListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("show.bs.modal", callback);
};

export const commonChangeFilterAddDate = (
  date,
  num = 2,
  type: any = "days"
): string => {
  // UTC 德国时区
  return moment
    .tz(date, "Europe/Berlin")
    .add(num, type)
    .startOf("days")
    .utc()
    .format();
  // UTC 0时区
  return moment(date, "YYYY-MM-DD")
    .add(num, type)
    .startOf("days")
    .utc(true)
    .toISOString();
};

const showServerErrorMsg = (res) => {
  const errorMsg = res.data;
  let htmlMsg = "";
  if (errorMsg) {
    for (const key in errorMsg) {
      htmlMsg += errorMsg[key][0] + "<br/>";
    }
  }
  if (res.sub_code.indexOf("FORM-VALIDATION") != -1) {
    //服务端数据验证错误
    Swal.fire({
      title: res.sub_msg,
      icon: "error",
      html: htmlMsg,
    });
  } else {
    //服务端其他错误
    Swal.fire({
      title: res.msg,
      text: res.sub_msg,
      icon: "error",
    });
  }
};

/**
 * @description: 公共下载文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonExportFile = (data, name?, suffix_name?) => {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      // 说明是普通对象数据，后台转换失败
      const jsonData = JSON.parse(this.result as string);
      showServerErrorMsg(jsonData);
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      const disposition = data.headers["content-disposition"];
      if (!name) {
        console.log("headers.content-disposition:", disposition);
        const fileNameString = disposition.split("filename=")[1];
        let fileName = fileNameString.split('"').join("").split("'").join("");
        if (fileName.indexOf("filename*=utf-8") > -1) {
          fileName = decodeURI(fileName.split("filename*=utf-8")[1]);
        }
        if (suffix_name) {
          fileName = decodeURIComponent(`${fileName}${suffix_name}`);
        } else {
          fileName = decodeURIComponent(fileName);
        }

        fileDownload(data.data, decodeURI(fileName));
      } else {
        const fileName = decodeURIComponent(name);
        fileDownload(data.data, decodeURI(fileName));
      }
    }
  };
  fileReader.readAsText(data.data);
};

export const formatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

export const formatDateTime = (
  date: CommonDateType,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

/**
 * @description: 判断是否是手机端，如果是，返回true
 * @return {*}
 */
export const isMobile = window.matchMedia("(max-width: 576px)"); // max-width与全局样式要统一
// 判断是否是手机端，如果是，返回true
// export const isMobile = () => {
//   const isFlag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   );
//   return isFlag;
// };

/**
 * @description: 处理ios移动端el-select聚焦bug
 * @return {*}
 */
export const removeReadonly = (async, currentInstance) => {
  nextTick(() => {
    if (!async) {
      // ios 手机有延迟问题
      setTimeout(() => {
        Object.keys(currentInstance.ctx._.refs).forEach((item) => {
          if (item.match("select")) {
            if (currentInstance.ctx._.refs[item] instanceof Array) {
              (currentInstance.ctx._.refs[item] as Array<any>).map((item) => {
                item.$el
                  .querySelector(".el-input__inner")
                  .removeAttribute("readonly");
              });
            } else {
              currentInstance.ctx._.refs[item]?.$el
                .querySelector(".el-input__inner")
                .removeAttribute("readonly");
            }
          }
        });
      }, 200);
    }
  });
};

/**
 * @description: 获取列表
 * @return CommonModuleRouter
 */
export const getModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): CommonModuleRouter => {
  if (route.path.match("/products/")) {
    return router
      ? getProductsDetailModule(language, route, router)
      : getProductsModule(language, route);
  }
  return router
    ? getProductsDetailModule(language, route, router)
    : getProductsModule(language, route);
};

/**
 * @description: 设置BCN
 * @return CommonModuleRouter
 */
export const setModuleBCN = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): void => {
  if (router) {
    const moudleResult = getModule(language, route, router);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      {
        name: moudleResult.parentName,
        path: "",
      },
      {
        name: moudleResult.listName,
        path: moudleResult.listPath,
      },
    ]);
  } else {
    const moudleResult = getModule(language, route);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      { name: moudleResult.parentName, path: "" },
    ]);
  }
};
