/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2023-11-09 22:39:41
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-09 21:29:13
 * @FilePath: \magicbean-saas-pim\src\core\directive\function\products.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getProductsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setDoubleSaleOrderName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDoubleSaleOrderName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/products/";
  result.parentName = language("common.products");
  if (route.path.match("information")) {
    strName = language("dsSkuManagement.dsSkuManagement");
    listPath += "ds-sku-management";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getProductsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "products-ds-sku-management":
      result.currentName = language("dsSkuManagement.dsSkuManagement");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "products-ds-sku-management-information":
      result.currentName = language("dsSkuManagement.Information");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "products-hs-code-management":
      result.currentName = language("hsCodeManagement.hsCodeManagement");
      setDoubleSaleOrderName(language, route, result, router);
      break;
  }
  return result;
};
