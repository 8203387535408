import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getDsOrderList(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/tools/request-process-order/list",
      params
    );
  },
  getDsSkuDetails(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/tools/request-process-order/info",
      params
    );
  },
  getHSCodeMaterial(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/customs/get-information",
      params
    );
  },
  getQuickFillSearchData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/pim-product/search",
      params
    );
  },
  saveSKU(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/tools/request-process-order/item/store",
      params
    );
  },
  publishAllSKU(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/tools/request-process-order/publish",
      params
    );
  },
  downloadSkuImages(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/tools/request-process-order/download",
      params
    );
  },
  getSizeSourceData(params) {
    return ApiService.post(Config.pimApiUrl + "/api/data-source/size", params);
  },
  reportException(params) {
    return ApiService.post(
      Config.pimApiUrl +
        "/api/product/tools/request-process-order/item/exception",
      params
    );
  },
};
