enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  LOGIN = "login",
  LOGOUT = "logout",
  VERIFY_AUTH = "verifyAuth",
  CHECK_TOKEN = "checkToken",
  CHECK_PERMISSION = "checkPermission",
  REFRESH_TOKEN = "refreshToken",
  GET_MENU_ITEMS = "getMenuItems",
  UPDATE_ACTION_PERMITS = "updateActionPermits",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_KEEPALIVE_ACTION = "addKeepAliveAction",
  DEL_KEEPALIVE_ACTION = "delKeepAliveAction",
  CLEAR_KEEPALIVE_ACTION = "clearKeepAliveAction",
  GET_PAGE_CONFIG = "getPageConfig",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_MENU_ITEMS = "setMenuItems",
  SET_ACTION_PERMITS = "setActionPermits",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  ADD_KEEPALIVE_MUTATION = "addKeepAliveMutation",
  DEL_KEEPALIVE_MUTATION = "delKeepAliveMutation",
  CLEAR_KEEPALIVE_MUTATION = "clearKeepAliveMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PAGE_CONFIG = "setPageConfig",
  SET_CURRENT_PAGE_CONFIG = "setCurrentPageConfig",
  SET_WINDOW_IS_MOBILE = "setWindowIsMobile",
}

export { Actions, Mutations };
